// We can only run this if both the types of objects are the same
function getDiff (original, updated, key) {
  if (original == null || updated == null) return false
  if (typeof (original) !== typeof (updated)) return false
  // console.log(original, updated)
  if (Array.isArray(original) && Array.isArray(updated)) {
    return getDiffArray(original, updated, key)
  } else if (typeof (original) === typeof (updated) && typeof (original) === 'object') {
    return getDiffObj(original, updated).map((row) => { return { ...row, code: key + '.' + row.code } })
  } else if (original !== updated) {
    return [{ code: key, action: 'updated', original, updated }]
  } else return false
}

function getDiffArray (original, updated, key) {
  // let debug = false
  // if (key === 'address') {
  //   debug = true
  //   console.log('debug is active for addresses')
  //   console.log(original)
  //   console.log(updated)
  // }
  let arr = []
  let updMatches = {}
  original.forEach((row, index) => {
    // if (debug) console.log('Processing ' + key + ' row: ' + row[key])
    // Look for matches on a "primary" field (a field which matches the name of the key that is passed)
    let matched = false
    let diff = 0
    let updatedFields = {}
    if (key in row) {
      for (let i = 0; i < updated.length; i++) {
        let updRow = updated[i]
        // If the key is in the updated row and the value for the given key matches the row
        if (key in updRow && updRow[key] === row[key]) {
          // if (debug) {
          //   console.log('matched ' + row[key] + ' to ' + updRow[key])
          //   console.log(row)
          //   console.log(updRow)
          // }
          matched = true
          updatedFields[key] = row[key]
          updMatches[i] = true
          for (let l in updRow) {
            if (!updRow.hasOwnProperty(l)) continue
            // Look to see if there are any other differences
            if (!(l in row) || row[l] !== updRow[l]) {
              if (!(l in row) && updRow[l] === '') continue
              updatedFields[l] = updRow[l]
              diff++
            }
          }
          break
        }
      }
    }
    if (matched && diff > 0) {
      // if (debug) console.log('matched with differences')
      let origData = {}
      for (let l in updatedFields) origData[l] = row[l]
      arr.push({ code: key, key, value: row[key], action: 'updated', original: origData, updated: updatedFields })
    } else if (!matched) {
      // if (debug) console.log('no match')
      arr.push({ code: key, action: 'removed', original: JSON.parse(JSON.stringify(row)) })
    } else {
      // if (debug) console.log('matched, no differences')
    }
  })
  updated.forEach((row, i) => {
    if (!(i in updMatches)) {
      arr.push({ code: key, action: 'added', updated: { ...row } })
    }
  })
  return arr
}

function getDiffObj (original, updated, ignoreMissingOriginal) {
  let arr = []
  for (let l in updated) {
    if (!updated.hasOwnProperty(l)) continue
    if (!(l in original)) {
      arr.push({ code: l, action: 'added', updated: updated[l] })
      continue
    }
    if (typeof (original[l]) !== typeof updated[l]) {
      arr.push({ code: l, action: 'updated', original: original[l], updated: updated[l] })
      continue
    }
    if (Array.isArray(original[l]) && Array.isArray(updated[l])) {
      getDiffArray(original[l], updated[l], l).forEach((row) => {
        arr.push({ ...row, code: l + '.' + row.code })
      })
    } else if (typeof original[l] === 'object') {
      getDiffObj(original[l], updated[l], ignoreMissingOriginal).forEach((row) => {
        arr.push({ ...row, code: l + '.' + row.code })
      })
    } else if (original[l] !== updated[l]) {
      arr.push({ code: l, action: 'updated', original: original[l], updated: updated[l] })
    }
  }

  if (!ignoreMissingOriginal) {
    for (let l in original) {
      if (!(l in updated)) {
        arr.push({ code: l, action: 'deleted', original: original[l] })
      }
    }
  }
  return arr
}

function getTimeDiff (time1, time2, returnSecondsOnly) {
  let _time1 = new Date(time1)
  let _time2 = new Date(time2)
  let totalSeconds = Math.floor((_time1 - _time2) / 1000)
  if (returnSecondsOnly) return totalSeconds
  const seconds = totalSeconds % 60
  const minutes = Math.floor(totalSeconds / 60) % 60
  const hours = Math.floor(totalSeconds / (60 * 60)) % 24
  const days = Math.floor(totalSeconds / (24 * 60 * 60))

  let text = []
  if (days > 0) {
    text.push(days + ' day' + (days > 1 ? 's' : ''))
  }
  if (hours > 0) {
    text.push(hours + ' hour' + (hours > 1 ? 's' : ''))
  }
  if (minutes > 0) {
    text.push(minutes + ' minute' + (minutes > 1 ? 's' : ''))
  }
  if (seconds > 0) {
    text.push(seconds + ' second' + (seconds > 1 ? 's' : ''))
  } else if (text.length === 0) {
    text.push('0 seconds')
  }
  return text.join(', ') + ' ago'
}

exports.getDiff = getDiff
exports.getDiffArray = getDiffArray
exports.getDiffObj = getDiffObj

exports.getTimeDiff = getTimeDiff
