var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',[_c('v-toolbar',[_c('v-toolbar-title',[_vm._v("Timeclock Manager")]),_c('v-spacer'),(_vm.user.roles.includes('Technology Services'))?_c('v-btn',{attrs:{"to":"/scotswork/timeclock/usage","icon":""}},[_c('v-icon',[_vm._v("fal fa-calendar-alt")])],1):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"to":"/scotswork/timeclock/detail","exact":"","icon":""}},on),[_c('v-icon',[_vm._v("fal fa-eye")])],1)]}}])},[_c('span',[_vm._v("View Timeclock scan detail")])]),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({},on),[_c('v-icon',[_vm._v(_vm._s(_vm.filter !== 'All' ? 'fas' : 'fal')+" fa-filter")])],1)]}}])},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{attrs:{"disabled":_vm.filter === 'All'},on:{"click":function($event){_vm.filter = 'All'}}},[_c('v-list-item-title',[_vm._v("Show All Configured Timeclocks")])],1),_c('v-list-item',{attrs:{"disabled":_vm.filter === 'Online'},on:{"click":function($event){_vm.filter = 'Online'}}},[_c('v-list-item-title',[_vm._v("Show Only Online Timeclocks")])],1),_c('v-list-item',{attrs:{"disabled":_vm.filter === 'Offline'},on:{"click":function($event){_vm.filter = 'Offline'}}},[_c('v-list-item-title',[_vm._v("Show Only Offline Timeclocks")])],1),_c('v-list-item',{attrs:{"disabled":_vm.filter === 'Unconfigured'},on:{"click":function($event){_vm.filter = 'Unconfigured'}}},[_c('v-list-item-title',[_vm._v("Show Only Unconfigured Timeclocks")])],1)],1)],1),(_vm.user.username === 'jon.moon')?_c('v-dialog',{attrs:{"width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialogOn = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltipOn = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},Object.assign({}, dialogOn, tooltipOn)),[_c('v-icon',[_vm._v("fal fa-plus")])],1)]}}],null,true)},[_c('span',[_vm._v("Add a new timeclock location")])])]}}],null,false,603205882),model:{value:(_vm.newTimeclockDialog),callback:function ($$v) {_vm.newTimeclockDialog=$$v},expression:"newTimeclockDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("New Timeclock Location")]),_c('v-card-text',[_c('v-text-field',{attrs:{"label":"Location Name"},model:{value:(_vm.newTimeclockLocation),callback:function ($$v) {_vm.newTimeclockLocation=$$v},expression:"newTimeclockLocation"}})],1),_c('v-card-actions',[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.newTimeclockDialog = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"text":"","color":"success"},on:{"click":function($event){return _vm.saveNewTimeclock()}}},[_vm._v("Save")])],1)],1)],1):_vm._e()],1),_c('v-data-table',{attrs:{"items":_vm.timeclocks,"headers":_vm.headers,"items-per-page":_vm.timeclocks.length,"footer-props":{ 'items-per-page-options': [20, 30] }},on:{"dblclick:row":function (e, ref) {
var item = ref.item;
 _vm.loadDetail(item._id) }},scopedSlots:_vm._u([{key:"item.ping",fn:function(ref){
var item = ref.item;
return [(!item.locked)?_c('v-alert',{staticStyle:{"margin-bottom":"0"},attrs:{"icon":"fas fa-exclamation-triangle","dense":""}},[_vm._v("-- Not Configured --")]):(item.pingSeconds > 300)?_c('v-alert',{staticStyle:{"margin-bottom":"0"},attrs:{"type":"error","dense":""}},[_vm._v(_vm._s(item.pingTimeAgo))]):(item.pingSeconds > 120)?_c('v-alert',{staticStyle:{"margin-bottom":"0"},attrs:{"type":"warning","dense":""}},[_vm._v(_vm._s(_vm.stringFormatDateToday(item.ping)))]):(item.ping)?_c('v-alert',{staticStyle:{"margin-bottom":"0"},attrs:{"type":"success","dense":""}},[_vm._v(_vm._s(_vm.stringFormatDateToday(item.ping)))]):_c('v-alert',{staticStyle:{"margin-bottom":"0"},attrs:{"type":"error","dense":""}},[_vm._v("Configured; never pinged server")])]}},{key:"item.scan",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.stringFormatDateToday(item.scan)))]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.loadDetail(item._id)}}},on),[_c('v-icon',[_vm._v("fal fa-eye")])],1)]}}],null,true)},[_c('span',[_vm._v("View timeclock scan detail for "+_vm._s(item.location))])])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }